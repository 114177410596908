import React, { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { colors } from "../consts/style"
import Img from "gatsby-image"
import Seo from "../components/Seo"
import BtnPrimary from "../components/buttons/ButtonRounded"
import { FormattedMessage} from 'react-intl';
import {
  PageWrapper,
  PageInner,
  PageTitle,
  SectionTitle,
  Text,
  FocusText,
  Grid2Col,
  Spacer,
} from "../components/Elements"

const accesContactQuery = graphql`
  {
    plan: file(relativePath: { eq: "plan-acces.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 567) {
          ...GatsbyImageSharpFixed
        }
        fluid(quality: 100, maxWidth: 567) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    page: datoCmsAccesContactPage {
      titre
      contenu
      titreContact
      contenuContact
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`

export default function SuccesPage() {
  const data = useStaticQuery(accesContactQuery)
  const {
    titre,
    contenu,
    titreContact,
    contenuContact,
    seoMetaTags,
  } = data.page

  return (
    <Fragment>
      <Seo meta={seoMetaTags} />
      <PageWrapper>
        <PageInner>
          <PageTitle>{titreContact}</PageTitle>
          <div>
            
            <Text
              color={colors.dark}
              dangerouslySetInnerHTML={{ __html: contenuContact }}
            />
            <FocusText>
            <FormattedMessage id="formulaire__succes"/>
              
            </FocusText>
          </div>
          <Spacer />
          <SectionTitle>{titre}</SectionTitle>
          <Grid2Col>
            <div>
              <Img fluid={data.plan.childImageSharp.fluid} />
              <Spacer />
            </div>
            <div>
              <div dangerouslySetInnerHTML={{ __html: contenu }} />
              <BtnPrimary as="a" rel="noopener noreferer nofollow" href="https://www.google.com/maps/dir//BOLLENE+AVENTURE,+1800+chemin+de+guffiage,+84500+Boll%C3%A8ne/@44.294081,4.787256,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x12b59e23d3310839:0x216c1f6157d9d2f4!2m2!1d4.787256!2d44.294081" target="_blank">
                 <FormattedMessage id="btn__itineraire"/>
              </BtnPrimary>
              <Spacer />
            </div>
          </Grid2Col>
        
          
        </PageInner>
      </PageWrapper>
    </Fragment>
  )
}
